import React, { useEffect } from 'react'
import { PageProps, navigate } from 'gatsby'
import { login } from '../../services/auth'

export default function LoginAsPage(props: PageProps) {
  const jwt = props.params['*']

  useEffect(() => {
    login(jwt)
    navigate('/')
  })

  return <>{null}</>
}
